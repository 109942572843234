import React from 'react'
import SEO from '../components/seo'

import '../styles/baike.scss'

const PageBaike = () => {
    return (
        <div className="PageBaike">
            <SEO title="康维他蜂蜜 | 关于我们 | 深圳市康维他天然食品有限公司" />
            <div>
                <div className="content bort">
                    <div className="header">
                        <div className="logo"><span>康维他蜂蜜</span></div>
                        <ul className="menu">
                            <li><a href="https://www.comvita.com.cn">首页</a></li>
                            <li><a href="https://www.comvita.com.cn/manuka-honey/zh/">产品服务</a></li>
                            <li><a href="https://www.comvita.com.cn/news-and-events/zh/">新闻资讯</a></li>
                            <li><a href="https://www.comvita.com.cn/brand-story/zh/">关于我们</a></li>
                        </ul>
                    </div>
                </div>
                <div className="conbody">
                    <div className="conright">
                        <div className="conat">
                            <h2><span>深圳市康维他天然食品有限公司</span></h2>
                            <p> 深圳市康维他天然食品有限公司专注新西兰蜂产品上市企业，提供产品包括麦卢卡蜂蜜、新西兰蜂蜜、蜂胶片及胶囊、蜂蜜润喉糖等产品。</p>
                            <p>康维他创始人克劳德先生和艾伦先生始终坚信：“连接自然能帮助人们生活得更健康，更有活力”，并坚持“把有效天然，能够帮助改善人类健康的产品从新西兰带到客户手中”。</p>
                            <p>目前，康维他是全球麦卢卡蜂蜜上市公司，产品销往52个国家及地区，于2004年正式进军中国市场。</p>
                            <h2><span>品牌主张</span></h2>
                            <h3>“连接自然的美好”是康维他的创立初衷</h3>
                            <h2><span>品牌宗旨</span></h2>
                            <p>生产自己有信心的产品</p>
                            <h2><span>产品品类介绍</span></h2>
                            <ul className="prolis">
                                <li><h3>康维他麦卢卡蜂蜜系列</h3>
            新西兰蜜源:康维他麦卢卡蜂蜜原产地位于新西兰偏远、原始的地区，为此康维他需要使用直升机将蜂箱运送到森林地区，有时还利用无人机飞越森。 为了追溯蜜源，康维他自主研发了一套成熟的蜂巢管理系统Apiary Management System（AMS），将每个蜂巢、蜜桶、蜂蜜成品配上一条条形码，从蜜源到“货架”做到严格的质量把控。每批麦卢卡蜂蜜都经过独立机构UMF蜂蜜协会的认证，是品质的保证。
          </li>
                                <li><h3>康维他日常蜂蜜系列</h3>
                                    <p>康维他多花种蜂蜜:营养与美味，适合全家食用。</p>
                                    <p>康维他呀咪蜂蜜:适合儿童口味，满足儿童味觉享受。</p>
                                    <p>康维他三叶草蜂蜜:三叶草蜂蜜具有淡淡的水果味，质地醇厚，浓香细腻，香甜甜可口。</p>
                                    <p>康维他瑞瓦瑞瓦蜂蜜:瑞瓦瑞瓦蜂蜜采自新西兰北岛低洼森林及南岛⻢尔堡海峡，蜂蜜味道香甜可口，是新西兰天然原生品种。单一品种的瑞瓦瑞瓦蜂蜜较一般混合蜂蜜难采集。</p>
                                    <p>康维他瑞瓦瑞瓦蜂蜜:瑞瓦瑞瓦蜂蜜采自新西兰北岛低洼森林及南岛马尔堡海峡，蜂蜜味道香甜可口，是新西兰天然原生品种。单一品种的瑞瓦瑞瓦蜂蜜较一般混合蜂蜜难采集，因此显得珍贵。</p>
                                    <p>康维他野地蜂蜜:野地蜂蜜采自新西兰荒野崎岖的自然然环境，由麦卢卡混合蜂蜜和天然野花蜜混合而成，从蜂窝取材至产品装瓶都经细致处理 ，保存其天然成分。野地蜂蜜口感浓纯蜜香、质滑芳甘、馥郁芬芳耐人寻味。</p>
                                </li>
                                <li><h3>蜂胶系列</h3>
                                    <p>蜜蜂为了保护蜂巢免受细菌感染会采集特种树脂或树汁，混合分泌物与蜂蜡制成抗菌力强的蜂胶，涂抹于蜂巢入口以保护家园及幼虫。</p>
                                    <p>
                                        蜂胶是一种天然的高效免疫增强剂，对机体免疫系统具有广泛的作用。它能对胸腺、脾脏等免疫系统产生强有力的功能调整，使机体免疫功能处于动态平衡的最佳状态。 </p><p>
                                        蜂胶的免疫增强功能表现为免疫防御、免疫自稳、免疫监视三方面的生理功能，即机体对病菌、病毒的抗感染能力，识别和清除自身衰老组</p>
                                    <p>
                                        织细胞的能力，杀伤和清除异常突变细胞，抑制恶性肿瘤生长的能力。</p>
                                </li>
                                <li><h3>口腔健康系列</h3>
                                    <p>蜂胶牙膏:康维他取材于大自然，运用科学技术配制出天然蜂胶牙膏，以天然成分取代人工化合物，减少因刷牙时误食化学物质对身体造成的损害，不含防腐剂、氟化物及人造香料。</p>
                                    <p>蜂胶口腔喷剂:康维他口腔喷剂特别混合了蜂胶和 UMF 10+ 麦卢卡蜂蜜及精油，让口腔保持自然清洁。</p>
                                </li>
                                <li><h3>润喉糖系列</h3>
                                    <p>结合了活性UMF 10+ 麦卢卡蜂蜜、薄荷油等天然有效成分，不添加人工色素、甜味剂、防腐剂。</p>
                                    <p>麦卢卡蜂蜜硬糖</p>
                                    <p>麦卢卡蜂蜜棒棒糖</p>
                                    <p>蜂胶润喉糖</p>
                                </li>
                                <li><h3>牛奶系列</h3>
                                    <p>麦卢卡蜂蜜调制奶粉</p>
                                    <p>奶片糖果</p>
                                </li>
                                <li><h3>蜜糖露系列</h3></li>
                                <li><h3>鱼油系列</h3></li>
                                <li><h3>医疗蜂蜜系列</h3>
                                    <p>采用医疗级麦卢卡蜂蜜符合医疗用品规格的终端消毒处理，可安全使用于任何大小伤口。</p>
                                    <p>医疗级抗菌蜜蜂软膏</p>
                                    <p>天然特效修护膏</p>
                                    <p>天然润肤霜
            </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="conleft"><div className="gimg"><img src="https://images.ctfassets.net/wuskojilmlb1/119qUTUmWmhy93Y9VMWQmb/29187cbcdba5fa658ec0b4f76a4cb644/manuka_spoon_400x400px.jpg?w=400&h=400&q=50&fm=webp" /></div></div>
                </div>
                <div className="clearfix" />
                <div className=" bortop tecm">版权所有 Copyright© 深圳市康维他天然食品有限公司
                All rights reserved
  </div>
            </div>

        </div>
    )
}

export default PageBaike